// inc
@import "inc/inc";

.no-flexbox {
    .navbar-toggleable-md > .container {
        display: block !important;
    }
}

@include media-breakpoint-up('lg') {
    .no-flexbox {
        .navbar-toggleable-md > .container {

            .navbar-collapse {
                display: block !important;
                float: right;
                width: auto;
            }
            .navbar-nav {
                float: right;
            }
            .navbar-brand {
                display: inline-block;
                float: left;
            }
        }
    }

}
