.no-flexbox .navbar-toggleable-md > .container {
  display: block !important;
}

@media (min-width: 1024px) {
  .no-flexbox .navbar-toggleable-md > .container .navbar-collapse {
    display: block !important;
    float: right;
    width: auto;
  }
  .no-flexbox .navbar-toggleable-md > .container .navbar-nav {
    float: right;
  }
  .no-flexbox .navbar-toggleable-md > .container .navbar-brand {
    display: inline-block;
    float: left;
  }
}
